import Vue from 'vue'
Vue.mixin({
    methods: {
        numberFormat(num) {
            let number = Math.round(num);
            var str = number.toString().split('.');
            if (str[0].length >= 4) {
                str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
            }
            return str.join('.');
        },
        removeTagsHtml(value) {
            var div = document.createElement("div");
            div.innerHTML = value;
            var text = div.textContent || div.innerText || "";
            return text
        },
        reduceText(text, count){
            if (typeof text !== 'string') {
                return ''; 
            }
            return text.slice(0, count) + (text.length > count ? "..." : "");
        },
        capitalizeFirstLetterOfAllWordsExceptFirst(text) {
            if (typeof text !== 'string') {
                return ''; 
            }
            
            const words = text.trim().split(' ');
            const capitalizedWords = words.map((word) => {
                return word.toUpperCase(); 
            });
            
            return capitalizedWords.join(' ');
        },
        /*
        checkImageUrl: async function(url) {
            let urlImageDefault = 'https://marketplace.b4b.com.co/marketplaceback/public/imagen/items/no-image.png';
            try {
                const response = await fetch(url);
                if (response.status === 200) {
                    return url;
                } else {
                    return urlImageDefault;
                }
            } catch (error) {
                return urlImageDefault;
            }
        }*/
        
    }
})