<template>
  <div>
    <Loading :active.sync="loading"></Loading>
      <router-view/>

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapState } from 'vuex'
export default {
  components:{
      Loading
    },
    computed:{
      ...mapState(['loading'])
    },
  
  name: 'App',
  data: () => ({
    //
  }),
};
</script>

<style>
  .container{
    max-width: 1300px;
  }
</style>