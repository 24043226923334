export function SET_SUCURSALES (state, sucursales){
    state.sucursales = sucursales
}

export function SET_SUCURSAL_SELECTED (state, selected){
    state.sucursalSelected = selected
}

export function SET_CART_ITEM_COUNT (state, itemCount){
    state.cartItemCount  = itemCount
}

export function SET_CATEGORIES_HOYOSTOOL (state, categories){
    state.categoriesHoyostool  = categories
}

export function SET_CATEGORIES_EMPRESA (state, categories){
    state.categoriesEmpresa  = categories
}

export function SET_CONFIGURATION_STYLE (state, configuration){
    state.background  = configuration.color
    state.empresaLogo = configuration.logo
    state.opcionExistencia = configuration.opcionExistencia
    state.opcionPrecioPropio = configuration.opcionPrecioPropio
}

export function TOGGLE_SIDEBAR (state){
    state.toggle = !state.toggle
}

export function CLEAR_CONFIGURATION (state){
    state.sucursales = []
    state.sucursalSelected = null
    state.cartItemCount =''
    state.categoriesHoyostool = []
    state.categoriesEmpresa = []
    state.background  = '#7386D5'
    state.empresaLogo = ''
    state.opcionPrecioPropio = ''
}

export function SET_TYPE_SEARCH (state, selected){
    state.typeSearch = selected
}

export function SET_CONFIGURATION_CLIENTE_WEB_STYLE (state, configuration){
    state.configuracionClienteWeb  = configuration
    state.imagesConfig = configuration.imagesConfig
}

    