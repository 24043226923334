export default{
    sucursales: [],
    sucursalSelected: null,
    cartItemCount: '',
    categoriesHoyostool: [],
    categoriesEmpresa: [],
    toggle: false,
    background: '#7386D5',
    opcionExistencia: '',
    empresaLogo: '',
    opcionPrecioPropio: '',
    urlWebBackend: require('../../../../settings.json').webBackend,
    typeSearch: '1',
    configuracionClienteWeb: {},
    imagesConfig: []

}