export async function getSucursalesByEmpresa ( context ){
    try{
        return context.rootState.conection.post('/cart/get-sucursales-empresa')
        .then(response =>{
            if(response.data.error == false){
                context.commit("SET_SUCURSALES", response.data.sucursales)
            }
        })
        .catch(error => {
            console.log(error);
        })
    }catch (e){
        console.log(e)
    }
}

export async function getSucursalSelected ( context, selected ){
    try{
        return context.rootState.conection.post('/cart/get-sucursal-name', selected )
        .then(response =>{
            context.commit("SET_SUCURSAL_SELECTED", response.data.sucursalName)
        })
        .catch(error => {
            console.log(error);
        })
    }catch (e){
        console.log(e)
    }
}

export async function getCartItemsCountByUser ( context ){
    try{
        return context.rootState.conection.post('/cart/get-cart-items-count-user')
        .then(response =>{
            if(response.data.error == false){
                context.commit("SET_CART_ITEM_COUNT", response.data.itemCount)
            }
        })
        .catch(error => {
            console.log(error);
        })
    }catch (e){
        console.log(e)
    }
}

export async function getCategoriesHoyostool ( context ){
    try{
        return context.rootState.conection.post('/articulos/categoria/get-category')
        .then(response =>{
            if(response.data.error == false){
                context.commit("SET_CATEGORIES_HOYOSTOOL", response.data.categorias)
            }
        })
        .catch(error => {
            console.log(error);
        })
    }catch (e){
        console.log(e)
    }
}

export async function getCategoriesEmpresa ( context ){
    try{
        return context.rootState.conection.post('/empresa/categories/get-categories-tree')
        .then(response =>{
            context.commit("SET_CATEGORIES_EMPRESA", response.data.categoriesList)
        })
        .catch(error => {
            console.log(error);
        })
    }catch (e){
        console.log(e)
    }
}

export async function getConfiguration ( context ){
    try{
        return context.rootState.conection.post('/configuracion/get-configuration')
        .then(response =>{
            if(response.data.error == false){
                context.commit("SET_CONFIGURATION_STYLE", response.data.configuracion)
            }
        })
        .catch(error => {
            console.log(error);
        })
    }catch (e){
        console.log(e)
    }
}

export async function setTypeSearchSelected ( context, selected ){
    try{
        context.commit("SET_TYPE_SEARCH", selected)
    }catch (e){
        console.log(e)
    }
}

export async function getConfigurationClienteWeb ( context ){
    try{
        return context.rootState.conection.post('/cliente-web/configuracion/get-configuration-cliente-web')
        .then(response =>{
            if(response.data.error == false){
                context.commit("SET_CONFIGURATION_CLIENTE_WEB_STYLE", response.data.configuracion)
            }
        })
        .catch(error => {
            console.log(error);
        })
    }catch (e){
        console.log(e)
    }
}