import Vue from 'vue'
import VueRouter from 'vue-router'
import allRoutes from './routes'

import store  from '@/store'

Vue.use(VueRouter)

const routes = allRoutes

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isLogged = store.state["auth"].isLogged;
  if ( ! requiresAuth && isLogged ){
    next('/dash/inicio')
  } 
  if ( requiresAuth && ! isLogged){
    next('/')
  } else{
    next()
  }
}) 


export default router
