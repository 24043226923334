export default [
    {
      path: '/dash',
      name: 'template',
      component: () => import(/* webpackChunkName: "dash" */ '../views/Template/Template.vue'),
      meta: { requiresAuth: true},
      children: [
        {
          path: 'inicio',
          name: 'inicio',
          component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
        },
        {
          path: 'perfil',
          name: 'perfil',
          component: () => import(/* webpackChunkName: "perfil" */ '../components/perfil/pages/Perfil.vue')
        },
        {
          path: 'canasta',
          name: 'canasta',
          component: () => import(/* webpackChunkName: "canasta" */ '../components/canasta/Canasta.vue'),
          children: [
            {
              path: 'procesar-carrito',
              name: 'processCartClienteWeb',
              component: () => import(/* webpackChunkName: "listadoCanasta" */ '../components/canasta/clienteWeb/pages/Cart.vue')
            },
          ]
        },
        {
          path: 'catalogo/:categoryCode/:slug',
          name: 'listadoInventario',
          component: () => import(/* webpackChunkName: "listadoInventario" */ '../components/InventoryProveedor/pages/InventoryList.vue')
        },
        {
          path: 'pedidos-cliente-web',
          name: 'pedidos-cliente-web',
          component: () => import(/* webpackChunkName: "pedidos" */ '../components/pedidosClienteWeb/pages/Pedidos.vue'),
          children: [
            {
              path: 'listado',
              name: 'ListadoPedidosClienteWeb',
              component: () => import(/* webpackChunkName: "listadoPedidos" */ '../components/pedidosClienteWeb/pages/ListarPedidos.vue')
            },
            {
              path: 'pedido/:id',
              name: 'InfoPedidoClienteWeb',
              component: () => import(/* webpackChunkName: "infoPedidos" */ '../components/pedidosClienteWeb/pages/InfoPedido')
            },
          ]
        },
        {
          path: 'busqueda/:texto/:time',
          name: 'SearchItems',
          component: () => import('../components/searchItems/Pages/SearchItem.vue')
        },
        {
          path: 'busqueda/detail/:referencia',
          name: 'ItemDetail',
          component: () => import('../components/searchItems/Pages/Iteminfo.vue')
        },
        {
          path: 'catalogo/:id/:category',
          name: 'inventoryEmpresaList',
          component: () => import(/* webpackChunkName: "inventoryListEmpresa" */ '../components/InventoryEmpresa/pages/InventoryEmpresaList.vue')
        },
        {
          path: 'busqueda/:codigo',
          name: 'itemEmpresaDetail',
          component: () => import(/* webpackChunkName: "itemEmpresaDetail" */ '../components/InventoryEmpresa/pages/ItemEmpresaDetail.vue')
        },
      ]
    },
    {
      path: '/',
      name: 'login',
      meta: {title: 'Buckets' },
  
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../components/login/Login.vue')
    },
  ]