import Vue from 'vue'
import Vuex from 'vuex'
import conectar from "@/axios"
import router from '@/router';

import auth from './modules/auth/index'
import configuration from './modules/configuration/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    conection: conectar,
    route: router,
    loading: false
  },
  mutations: {
    setLoading (state, bool){
      state.loading = bool
    }
  },
  actions: {
  },
  modules: {
    auth,
    configuration
  }
})
