import Vue from 'vue'
import App from './App.vue'
import router from './router'

import conectar from "@/axios"
import store from './store'

require('./plugins')

import '@/helpers/helpers'

Vue.config.productionTip = false

import 'material-design-icons-iconfont/dist/material-design-icons.css'

conectar.interceptors.response.use(function (response) {
  if(response.data.error) {
    let logoutMessages = ['Token invalido','Token Expirado', 'Token de validacion no encontrado']
    if(logoutMessages.includes(response.data.mensaje)) {
        router.push({ name: 'login'});
    }
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});

Vue.use({
  install (Vue) {
    Vue.prototype.$conectar = conectar
  }
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
