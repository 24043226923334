export function SET_USER(state, user){
    state.user = user
    state.isLogged = true
    if(user.empresa_id){
        state.empresa = user.empresa
    }
}

export function LOGOUT (state) {
    state.user = null
    state.empresa = null
    state.isLogged = false
}