import router from '@/router';
const delay = time => new Promise(res=>setTimeout(res,time));
export async function signIn (context, user){
    try{
        context.commit('setLoading', true, {root:true})
        return new Promise((resolve, reject) => {
            context.rootState.conection.post('cliente-web/login', user)
            .then(response =>{
                if(response.data.error === true){
                    //return response.data;
                    const errorMessage = response.data.message;
                    reject(errorMessage)
                }else{
                    const token = response.data.message.token;
                    localStorage.setItem('user-token', token);
                    context.rootState.conection.interceptors.request.use(
                        config => {
                        const token = localStorage.getItem('user-token');
                        config.headers.Authorization = `Bearer ${token}`;
                        return config;
                        },
                        error => Promise.reject(error)
                    );
                    context.dispatch("getUser");
                    resolve()
                }
            })
            .catch((error) => {
                reject('Al parecer hubo un error en el servidor')
                console.log(error)
            });
        })
    }catch (e){
        console.log(e);
    }finally{
        context.commit('setLoading', false, {root:true})
    }
}

export async function getUser ( context ){
    try{
        context.commit('setLoading', true, {root:true})
        await context.rootState.conection.post('cliente-web/user')
        .then(async response =>{
            if(response.data.error === true){
                localStorage.clear();
            }else{
                context.commit("SET_USER", response.data.user)
                localStorage.setItem('user_name', response.data.user.nombre);
                localStorage.setItem('user_permissions', response.data.user.permissions);
                
                await this.dispatch("configuration/getSucursalesByEmpresa", {root:true});
                await this.dispatch("configuration/getSucursalSelected", {root:true});
                await this.dispatch("configuration/getCartItemsCountByUser", {root:true});
                await this.dispatch("configuration/getCategoriesHoyostool", {root:true});
                localStorage.setItem('empresa_permissions', response.data.user.empresa.permissions);
                if(context.state.empresa.permissions.includes('can_use_module_items_empresa')){
                    await this.dispatch("configuration/getCategoriesEmpresa", {root:true});
                }
                await this.dispatch("configuration/getConfigurationClienteWeb", {root:true});
                document.title = response.data.user.empresa.nombre;
                
                await this.dispatch("configuration/getConfiguration", {root:true});
                await delay(3000);
                await router.push({ name: 'inicio' });
            }
        })
        .catch(error => {
            console.log(error);
        })
    }catch (e){
        console.log(e)
    }finally{
        context.commit('setLoading', false, {root:true})
    }
}

export async function logout (context){
    try{
        context.commit('setLoading', true, {root:true})
        context.commit("LOGOUT")
        this.commit("configuration/CLEAR_CONFIGURATION", {root:true})
        localStorage.clear();
        await delay(3000);
        await router.push({ name: 'login' });
    
    }catch (e){
        console.log(e)
    }finally{
        context.commit('setLoading', false, {root:true})
    }
}